.footer {
  overflow: hidden;
  width: 100%;
  .footer-end-row {
    display: inline-flex;
    padding-right: 25px;

    a {
      font-family: Lato;
      font-size: 16px;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    .privacy-notice {
      margin-right: 5px;
    }

    .Accessibility-link {
      margin-left: 5px;
    }
  }

  .site-brand {
    display: flex;
  }

  .brand-logo {
    display: flex;
    width: auto;
    height: 80px;
    align-items: center;
    justify-content: center;
    padding: 15px;
    aspect-ratio: 1/1;
    background-color: #002957;
  }

  .brand-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    color: #002957;
    font-family: Aleo;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  .social-icons {
    display: flex;
    max-width: 500px;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
  }

  .privacy-notice a {
    color: #002957;
  }
}

.footer > .row {
  padding-bottom: 0 !important;
}

.ui.vertical.segment[role="contentinfo"]:has(.footer){
  padding-bottom:0!important;
  border-bottom: none;
}
