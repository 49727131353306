/* components/manage/Blocks/VideoJS/videojs-block.less */

.range-slider-container {
  display: flex;
  height: 10vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;

  .range-slider {
    position: relative;
    width: 100%;
  }

  .range-slider-track,
  .range-slider-range,
  .range-slider-left-value,
  .range-slider-right-value {
    position: absolute;
  }

  .range-slider-track,
  .range-slider-range {
    height: 5px;
    border-radius: 3px;
  }

  .range-slider-track {
    z-index: 1;
    width: 100%;
    background-color: #ced4da;
  }

  .range-slider-range {
    z-index: 2;
    background-color: #007eb1;
  }

  .range-slider-left-value,
  .range-slider-right-value {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
  }

  .range-slider-left-value {
    left: 4px;
  }

  .range-slider-right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    position: relative;
    width: 100%;
    height: 0;
    outline: none;
    pointer-events: none;
  }

  .thumb-min {
    z-index: 3;
  }

  .thumb-max {
    z-index: 4;
  }

  .thumb-zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    position: relative;
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 50%;
    margin-top: 4px;
    background-color: #f1f5f7;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    pointer-events: all;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    position: relative;
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 50%;
    margin-top: 4px;
    background-color: #f1f5f7;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    pointer-events: all;
  }
}

.watermark {
  .align-watermark {
    display: flex !important;
    align-items: center;

    .ui.icon.button {
      padding: 7px;
      border-radius: 1px;
      margin-left: 4px;
      color: #826a6a !important;

      &:hover {
        background-color: #edf1f2 !important;
      }
    }

    .ui.buttons:first-child .ui.icon.button {
      margin-left: 0;
    }

    .ui.active.basic.icon.button {
      border: 1px solid #2996da;
      background: transparent !important;
    }
  }

  .type-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;

    .ui.radio.checkbox {
      margin-bottom: 10px;
    }
  }
}

.subtitles-editor-mode-form {
  border-bottom: 2px solid #c7d5d8;
  margin: 1em 0 2em 0;

  .subtitles-editor-mode-radio {
    label {
      padding-left: 21px !important;
    }
  }
}

.subtitles-editor-textform {
  textarea {
    &:focus {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
}

form.ui.form.subtitles-editor-form {
  .ui.input input {
    width: 100% !important;
  }

  .ui.label.subtitle-time-label {
    &:hover {
      background-color: #c3c3c3;
      cursor: pointer;
    }
  }
}

// Make videojs icons smaller on small screens..
@media only screen and (max-width: $tabletBreakpoint) {
  .video-js .vjs-control:not(.vjs-progress-control) {
    width: 2.5em;
  }
}
